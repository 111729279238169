import React from 'react';
import { dropDownProps } from './dropdown.interface';
import './dropdown.scss';

export default ({ dropDownVisible, firstApiCall, getSecondData, dispatch }: dropDownProps): JSX.Element => {
    const cityNames = firstApiCall.map(result =>
        <option
            key={result.formatted}
            label={result.formatted}
            value={
                `{"village":"${result.components.village}",
                 "place":"${result.components.place}",
                 "town":"${result.components.town}",
                 "city":"${result.components.city}",
                 "country":"${result.components.country}", 
                 "lat":${result.geometry.lat},
                 "lng":${result.geometry.lng}}`
            }
        >
            {result.formatted}
        </option>);
    const handleChange =  (event: React.ChangeEvent<HTMLSelectElement>) => {
        const lat = JSON.parse(event.target.value).lat;
        const lng = JSON.parse(event.target.value).lng;
        dispatch({ type: 'CurrentCityDisplay', payload: event.target.value });
        getSecondData(lat, lng);
    };

    return (
        <div className="dropdown">
            {dropDownVisible &&
                <select
                    value={'Please select your location'}
                    onChange={event => handleChange(event)}
                    className="form-select custom-select"
                >
                    <option>Please select your location</option>
                    {cityNames}
                </select>
            }
        </div>
    );
};
