import React, { useReducer } from 'react';
import DisplayCurrent from './displayCurrent/DisplayCurrent';
import DisplayForecast from './displayForecast/DisplayForecast';
import Dropdown from './dropdown/Dropdown';
import Form from './forecastForm/forecastForm';


import './forecastApp.scss';

function weatherReducer(state: any , action: any) {
    switch (action.type) {
    case 'FirstApiCalled': {
        return {
            ...state,
            firstApiCall: action.payload,
            cityName: '',
            dropDownVisible: true,
        };
    }
    case 'field': {
        return {
            ...state,
            [ action.field ]: action.value,
        };
    }
    case 'SecondApiCalled': {
        return {
            ...state,
            secondApiCall: action.payload.current.weather[ 0 ],
            temperature: action.payload.current.temp,
            forecast: action.payload.daily,
            dropDownVisible: false,
        };
    }
    case 'CurrentCityDisplay': {
        return {
            ...state,
            location: action.payload,
        };
    }
    default:
        break;
    }
    return state;
}

const initialState = {
    cityName: '',
    dropDownVisible: false,
    temperature: '',
    firstApiCall: [] as string[],
    secondApiCall: [] as string[],
    forecast: [] as string[],
    location: {},
};

export default (): JSX.Element => {
    const [ state, dispatch ] = useReducer(weatherReducer, initialState);
    const {
        cityName,
        dropDownVisible,
        firstApiCall,
        secondApiCall,
        forecast,
        temperature,
        location,
    } = state;

    const handleSubmit = async (e: React.SyntheticEvent<Element, Event>) => {
        e.preventDefault();
        if (cityName === '') {
            return;
        } else {
            try {
                const response = await fetch(`
                https://api.opencagedata.com/geocode/v1/json?q=${cityName}&
                lat={latitude}&lon={longitude}&key=9bf9c5ad76f64794b9d54ffeb407f386`)
                const myResponse = await response.json();
                const apiData = await myResponse.results;
                dispatch({ type: 'FirstApiCalled', payload: apiData });
            } catch (error) {
                alert(error);  
            }
            
        }
        
    };
    const getSecondData = async (lat: number, lng: number) => {
        try {
            const response = await fetch(`
            https://api.openweathermap.org/data/2.5/onecall?lat=${lat}&lon=${lng}&
            exclude=hourly,minutely&units=metric&appid=80d641a4fc656571a4c892f31c2b86be`);
            const myResponse = await response.json();
            const secondApiData = await myResponse;
            dispatch({ type: 'SecondApiCalled', payload: secondApiData });
        } catch (error) {
            alert(error)
        }
        
        
    };
    const unixConverter = (localTime: number) => {
        const unix_timestamp = localTime;
        const date = new Date(unix_timestamp * 1000);
        const day = date.getDate();
        const days = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];
        const weekday = days[ date.getDay() ];
        const months = [ 'Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec' ];
        const month = months[ date.getMonth() ];
        return `${weekday}, ${day} ${month}`;
    };
    const cityNameCheck = (location: {city?: string; town?: string; place?: string; village?: string}) => {
        return location.city !== 'undefined' ? location.city :
            location.town !== 'undefined' ? location.town : location.place !== 'undefined' ? location.place: location.village;
    };

    const weekly = forecast && forecast.slice(1,6).map((forecast: any, i: number) =>
        <DisplayForecast key={`${i}-${forecast}`} forecast={forecast} unixConverter={unixConverter} />,
    );

    return (
        <div className='forecast'>
            <h1>What is the weather today?</h1>
            <Form
                handleSubmit={handleSubmit}
                cityName={cityName}
                dispatch={dispatch}
            />
            <div>
                <Dropdown
                    dropDownVisible={dropDownVisible}
                    firstApiCall={firstApiCall}
                    getSecondData={getSecondData}
                    dispatch={dispatch}
                />
            </div>
            <div className='forecast__container'>
                <div className='forecast__main'>
                    <DisplayCurrent
                        secondApiCall={secondApiCall}
                        temperature={temperature}
                        location={location}
                        cityNameCheck={cityNameCheck}
                    />
                </div>
                <div className='forecast__sub'>
                    {weekly}
                </div>
            </div>
        </div>
    );
};
