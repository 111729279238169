import React from 'react';
import { displayCurrentProps } from './displayCurrent.interface';
import './displayCurrent.scss';

export default ({ secondApiCall, cityNameCheck, temperature, location }: displayCurrentProps): JSX.Element => {
    const { icon, description } = secondApiCall;
    return (
        <>
            {location.country &&
                <p className="current__location">
                    {`${cityNameCheck(location)}, ${location.country}`}
                </p>
            }
            {temperature &&
                <p className="current__temperatureValue">
                    {`${Math.floor(temperature)} \u00b0C`}
                </p>
            }
            {icon && <img src={`http://openweathermap.org/img/wn/${icon}@2x.png`}/>}
            {description &&
                <p className="current__temperatureDescription">
                    {description}
                </p>
            }
        </>
    );
};
