import React from 'react';
import { formProps } from './form.interface';
import './forecastForm.scss';

export default ({ handleSubmit, cityName, dispatch }: formProps) => (
    <form onSubmit={handleSubmit}>
        <input
            type="text"
            name="cityName"
            value={cityName}
            onChange={ e => dispatch({ type: 'field', field: 'cityName', value: e.target.value})}
            placeholder="Enter your city name"
            className="form-control custom"/>
        <button type="submit" className="btn btn-primary btn-grad">Submit</button>
    </form>
);
