import React from 'react';
import { displayForecastProps } from './displayForecast.interface';
import './displayForecast.scss';

export default ({ forecast, unixConverter }: displayForecastProps): JSX.Element => {
    return (
        <div className="forecastComponents">
            <p className="displayForecast__day">{unixConverter(forecast.dt)}</p>
            {forecast.temp.day &&
                <p className="displayForecast__temp">
                    {`${Math.floor(forecast.temp.day)} \u00b0C`}
                </p>
            }
            <img
                className="displayForecast__img"
                alt={forecast.weather[ 0 ].description}
                src={`http://openweathermap.org/img/wn/${forecast.weather[ 0 ].icon}@2x.png`}
            />
            {forecast.weather[ 0 ].description &&
                <p className="displayForecast__description">
                    {forecast.weather[ 0 ].description}
                </p>
            }
        </div>
    );
};
